import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

// import { Section } from '../components/common/section';

const TermsPages = (props) => {
  return (
    <Layout
      currentPage='qualified-surveys'
      contentPage={true}
    >
      <SEO 
        title="Qualified surveys" 
        keywords={[`Pepio`, `application`, `sondage`, `survey`]}
        description=" Grâce à Pepio, sonder l’opinion n’a jamais été aussi simple. Donnez votre avis, développez votre communauté, visualisez leurs réponses, tout simplement."
        meta={[
          {
            property: `og:url`,
            content: 'https://pepio.io',
          },
          {
            property: `fb:app_id`,
            content: '591813588041433',
          }
        ]}
      />

      <div className="wyswyg typeset">
        <h1>Qualified surveys</h1>
      </div>
      
    </Layout>
  )
};

export default TermsPages
